.home-rise {
  max-width: 835px;
  margin: 0 auto 140px;
}

.home-rise__title {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--c-pure-white);
}

.home-rise__grid {
  margin: 65px auto;
}

.home-rise__line {
  width: 100%;
  height: 8px;
  background: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
  opacity: 0.2;
  border-radius: 8px;
}

.home-rise__row {
  display: flex;
}

.home-rise__row--1 {
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: -42px;
}

.home-rise__row--2 {
  align-items: flex-start;
  justify-content: space-around;
  margin-top: -40px;
  padding: 0 80px;
  .home-rise__card-content {
    flex-direction: column-reverse;
  }
  .home-rise__point {
    transform: rotateX(180deg);
  }
}

.home-rise__card {
  position: relative;
  width: 160px;
}

.home-rise__card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.home-rise__card-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-pure-white);
}

.home-rise__point {
  margin: 0 auto;
}


.home-rise__actions{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.home-rise__btn{}

.home-rise__actions-logo{
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .home-rise {
    margin: 0 auto 64px;
    padding-right: 24px;
  }
  .home-rise__grid{
    max-width: 480px;
    margin: 48px auto 50px;
    padding: 0 16px;
  }

  .home-rise__line {
    position: absolute;
    top: 0;
    left: 0;
    bottom: -24px;
    width: 5px;
    height: auto;
    background: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
    opacity: 0.2;
    border-radius: 8px;
    transform: rotateZ(0deg);
  }

  .home-rise__row {
    position: relative;
    display: block;
  }

  .home-rise__row--1 {
    align-items: inherit;
    justify-content: inherit;
    margin-bottom: 0;
    .home-rise__point {
      transform: rotateZ(90deg);
    }
  }

  .home-rise__row--2 {
    align-items: inherit;
    justify-content: inherit;
    margin-top: 0;
    padding: 0;
    .home-rise__point {
      transform: rotateZ(90deg);
    }
  }

  .home-rise__card {
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  }

  .home-rise__card-content {
    position: relative;
    display: block;
    padding-left: 50px;
  }

  .home-rise__card-text {
    text-align: left;
  }

  .home-rise__point {
    position: absolute;
    top: -36px;
    left: -18px;
    margin: 0 auto;
  }

  .home-rise__actions{
    flex-direction: column-reverse;
    margin-top: 10px;
  }

  .home-rise__actions-logo{
    margin-top: 0;
    margin-bottom: 48px;
  }

  .home-rise__btn {
    max-width: 260px;
    width: 100%;
    font-size: 32px;
    line-height: 39px;
    padding: 12px 16px;
  }
}
