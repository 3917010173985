@import "../../../scss/mixin";

.home-program {
  position: relative;
  margin: 0 auto 200px;
  max-width: 1170px;
}

.home-program__grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.home-program__content {

}


.home-program__title {
  max-width: 480px;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  background: linear-gradient(288.18deg, #F2AD7D 11.11%, #A34EFC 89.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.home-program__text {
  max-width: 450px;
  margin-top: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-opacity-white);
}


.home-program__actions {
  margin-top: 24px;
}

.home-program__btn {
  width: 100%;
  max-width: 160px;
  white-space: nowrap;
}


.home-program__banner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 600px;
}

.home-program__reward {
  margin-top: 19px;
  margin-left: 47px;
  svg {
    margin-top: -15px;
  }
}

.home-program__reward-title {
  margin-left: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  background: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  mix-blend-mode: normal;
}

.home-program__card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 25px;
  border-radius: 28px;
  font-family: 'Open Sans';
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: -1;
    border-radius: inherit;
   }
  &--1 {
    margin-left: 80px;
    width: 305px;
    min-height: 95px;
    background: linear-gradient(272.91deg, #423FEB 10.99%, #161D1F 88.56%);
    box-shadow: inset 0 5px 10px #18012B;
    &:after {
      background: linear-gradient(140deg, rgba(66, 63, 235, 1) 10%,rgba(145, 143, 253, 1) 100%);
    }
    .home-program__card-line-1 {
      bottom: -5px;
      right: -5px;
      width: 50%;
      border-radius: 0 0 30px 0;
    }
    .home-program__card-line-2 {
      bottom: -6px;
      right: -6px;
      width: 50%;
      border-radius: 0 0 30px 0;
      background: linear-gradient(140deg,rgba(66, 63, 235, 1) 0%,rgba(66, 63, 235, 1) 100%);
    }
  }

  &--2 {
    margin-top: 34px;

    width: 240px;
    min-height: 80px;
    background: linear-gradient(318.21deg, #151618 14.05%, #20B1B1 85.29%);
    box-shadow: inset 0px 5px 10px #3A0770;
    &:after {
      background: linear-gradient(140deg, rgba(36, 242, 242, 1) 0%,rgba(45, 139, 254, 1) 100%);
    }
    .home-program__card-line-1 {
      top: -5px;
      right: -5px;
      width: 100%;
      border-radius: 0 30px 0 0;
    }
    .home-program__card-line-2 {
      top: -6px;
      right: -6px;
      width: 100%;
      border-radius: 0 30px 0 0;
      background: linear-gradient(140deg,rgba(36, 242, 242, 1) 0%,rgba(45, 139, 254, 1) 100%);
    }
    .home-program__card-title {
      color: rgba(255, 255, 255, 0.45);
    }
    .home-program__card-num {
      background: linear-gradient(132.87deg, #50FFFE 25.93%, #30B7EF 77.79%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &--3 {
    margin-top: 19px;
    margin-left: 27px;

    width: 278px;
    min-height: 95px;
    background: linear-gradient(109.79deg, #F2AD7D 11.27%, #A34EFC 90.34%);
    box-shadow: inset 0px 5px 10px #18012B;
    &:after {
      background: linear-gradient(140deg,rgba(242, 173, 125, 1) 0%,rgba(163, 78, 252, 1) 100%);
    }
    .home-program__card-line-1 {
      top: -5px;
      left: -5px;
      width: 90%;
      border-radius: 30px 0 0 0;
    }
    .home-program__card-line-2 {
      top: -6px;
      left: -6px;
      width: 90%;
      border-radius: 30px 0 0 0;
      background: linear-gradient(140deg,rgba(242, 173, 125, 1) 0%,rgba(163, 78, 252, 1) 100%);
    }
  }

  &--4 {
    margin-top: 30px;
    margin-left: 60px;

    width: 305px;
    min-height: 95px;
    background: linear-gradient(109.79deg, #0ABEBE 11.27%, #5B00BA 90.34%);
    box-shadow: inset 0px 5px 10px #18012B;
    &:after {
      background: linear-gradient(140deg,rgba(147, 252, 255, 1) 0%,rgba(180, 98, 255, 1) 100%);
    }
    .home-program__card-line-1 {
      bottom: -5px;
      left: -5px;
      width: 90%;
      border-radius: 0 0 0 30px;
    }
    .home-program__card-line-2 {
      bottom: -6px;
      left: -6px;
      width: 90%;
      border-radius: 0 0 0 30px;
      background: linear-gradient(140deg,rgba(147, 252, 255, 1) 0%,rgba(180, 98, 255, 1) 100%);
    }
  }
}

.home-program__card-line-1 {
  position: absolute;
  z-index: -1;
  height: 30px;
  background-color: var(--c-bg);
}

.home-program__card-line-2 {
  position: absolute;
  z-index: -1;
  height: 30px;
}

.home-program__card-content  {

}

.home-program__card-title{
  font-size: 20px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.75);
}

.home-program__card-text {
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.75);
}

.home-program__card-num {
  margin-left: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: right;
  color: #FFFFFF;
}


@media screen and (max-width: 1024px) {
  .home-program__grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .home-program__banner {
    margin-top: 48px;
  }

  .home-program__actions {
    margin-top: 48px;

  }
}



@media screen and (max-width: 768px) {
  .home-program {
    margin-bottom: 64px;
    padding-right: 24px;
  }

  .home-program__title {
    font-size: 32px;
    line-height: 39px;
  }

  .home-program__reward {
    margin: 13px 16px;
  }

  .home-program__card {
    padding: 16px 21px;
    &--1 {
      margin-left: 80px;
      width: 260px;
      min-height: 80px;
    }

    &--2 {
      margin-top: 34px;
      margin-left: 27px;
      width: 205px;
    }

    &--3 {
      margin-top: 19px;
      margin-left: 27px;

      width: 245px;
    }

    &--4 {
      margin-top: 30px;
      margin-left: 60px;

      width: 270px;

    }
  }

  .home-program__card-title {
    font-size: 16px;
    line-height: 19px;
  }

  .home-program__card-text {
    font-size: 10px;
    line-height: 12px;
  }

  .home-program__card-num {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 580px) {
  .home-program__banner {
    flex-direction: column;
  }

  .home-program__reward {
    order: 1;
    margin: 13px auto;
  }

  .home-program__card {
    &--1 {
      margin-left: 52px;
    }

    &--2 {
      margin-top: 17px;
      margin-left: 21px;
    }

    &--3 {
      margin-top: 13px;
      margin-left: 80px;

      width: 245px;
    }

    &--4 {
      margin-top: 8px;
      margin-left: 36px;
    }
  }
}
