@import "../../../scss/mixin";

.influencer-modal {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;

  &--active {
    .influencer-modal__overlay {
      opacity: .6;
      visibility: visible;
    }

    .influencer-modal__wrapper {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -52%);
    }
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--c-dark);
    opacity: 0.8;
    visibility: hidden;
    @include trCubic();
  }

  &__wrapper {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    padding: 24px;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -100%);
    background: linear-gradient(180deg, #091333 0%, #0F224B 100%);
    border-radius: 16px;
    @include trCubic();
  }

  &__content {

  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

  }

  &__title {
    margin-bottom: 24px;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: var(--c-pure-white);
  }

  &__form {
  }

  &__field {
    & + & {
      margin-top: 16px;
    }

    input {
      width: 100%;
      padding: 8px 16px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.05em;
      color: var(--c-pure-white);
      background: rgba(217, 217, 217, 0.05);
      border: 1px solid var(--c-input-border);
      border-radius: 9px;
      @include trCubic();

      &::-webkit-input-placeholder {
        color: var(--c-btn-disabled-text);
      }

      &:focus {
        border: 1px solid var(--c-primary);
        outline: var(--c-primary);
      }

      &.influencer-modal__field--error {
        border: 1px solid var(--c-error);
      }
    }

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--c-btn-disabled-text);
    }

    &--input {

    }

    &-error {
      padding-top: 8px;
      color: var(--c-error);
      font-size: 12px;
      line-height: 16px;
      @include trCubic();
    }
  }

  &__social {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;

    li {
      position: relative;
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      @include trCubic();
      cursor: pointer;

      &:hover, &:active {
        background: rgba(36, 242, 242, 0.2);
        border: 1px solid var(--c-primary);
        border-radius: 4px;

        path {
          fill: var(--c-primary);
        }

        .home-nav__tooltip {
          opacity: 1;
          visibility: visible;
          transform: translateX(0px) scale(1);
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
    li + li {
      margin-left: 32px;
    }
  }

  &__actions {
    margin-top: 24px;
    text-align: center;
  }

  &__btn {
    width: 180px;
  }

  &__message-text {
    margin-top: 24px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: var(--c-pure-white);
  }
}

@media screen and (max-width: 768px){
  .influencer-modal {
    &__wrapper {
      max-width: 295px;
      padding: 16px;

    }

    &__icon {
      margin-bottom: 16px;
    }

    &__title {
      margin-bottom: 16px;
    }


    &__field {
      input, textarea {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__social {
      padding-top: 16px;
      li + li {
        margin-left: 8px;
      }
    }

    &__actions {
      margin-top: 16px;
    }

    &__btn {
      height: 40px;
      width: 160px;
    }

    &__message-text {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
