.home-worked {
  position: relative;
  margin: 0 auto 84px;
  text-align: center;
}

.home-worked__title {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: var(--c-pure-white);
}

.home-worked__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
}

.home-worked__list-item {
  margin: 16px 24px 0;
}

.home-worked__card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    display: inline-block;
    margin-left: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--c-opacity-white);
    white-space: nowrap;
  }
}

.home-worked__card-image {
  svg {
    width: 41px;
    height: auto;
  }
}

.home-worked__group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -12px;
  .home-worked__card-image {
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .home-worked__card {
    z-index: 6;
    &:not(:first-child) {
      position: relative;
      margin-left: -11px;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: var(--c-dark);
        border-radius: 50%;
      }
    }
    &:nth-child(2){
      z-index: 5;
      &:after {
        opacity: .4;
      }
    }
    &:nth-child(3){
      z-index: 4;
      &:after {
        opacity: .6;
      }
    }
    &:nth-child(4){
      z-index: 3;
      &:after {
        opacity: .6;
      }
    }
    &:nth-child(5){
      z-index: 2;
      &:after {
        opacity: .7;
      }
    }
    &:nth-child(6){
      z-index: 1;
      &:after {
        opacity: .7;
      }
    }
    &:nth-child(7){
      z-index: 0;
      &:after {
        opacity: .8;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-worked {
    margin: 0 auto 64px;
  }

  .home-worked__list-item {
    margin: 16px 8px 0;
  }

  .home-worked__card {
    span {
      margin-left: 11px;
      font-size: 14px;
    }
  }

  .home-worked__card-image {
    svg {
      width: 32px;
    }
  }

  .home-worked__group {
    margin-left: 8px;
    .home-worked__card-image {
      width: 25px;
      height: 25px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .home-worked__card {
      &:not(:first-child) {
        margin-left: -9px;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .home-worked{
    padding-right: 24px;
  }
}
