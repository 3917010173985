.home-head{
  position: relative;
  margin: 100px auto 100px;
  max-width: 1190px;
  text-align: left;
}

.home-head__title{
  max-width: 640px;
  font-weight: 700;
  font-size: 84px;
  line-height: 80px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #0059FF 35.18%, #11E9D9 63.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  h1 {
    display: inline;
  }
}

.home-head__btn {
  position: relative;
  z-index: 1;
  vertical-align: top;
  width: 160px;
  margin-left: 16px;
  -webkit-background-clip: padding;
  -webkit-text-fill-color: var(--c-dark);
  background-clip: padding;
  text-fill-color: var(--c-dark);
}

.home-head__sub-title{
  max-width: 630px;
  margin-top: 53px;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
  color: #7D2CD2;
}

.home-head__text{
  max-width: 640px;
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-pure-white);
}

.home-head__image{
  position: absolute;
  top: -80px;
  right: -60px;
}

.home-head__image-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  svg {
    &:nth-child(1) {
      position: absolute;
      top: 5px;
      left: 23%;
      width: 31.5%;
      height: 70.4%;
      background: rgba(163, 78, 252, 0.5);
      filter: blur(100px);
      transform: matrix(-0.96, -0.26, -0.26, 0.96, 0, 0);
    }
    &:nth-child(2) {
      position: absolute;
      top: 43%;
      left: 18%;
      width: 36.2%;
      height: 59.6%;
      background: linear-gradient(288.18deg, #FFC399 11.11%, #C48BFF 89.71%);
      opacity: 0.4;
      filter: blur(75px);
      transform: rotate(128.55deg);
    }
  }
}

@media screen and (max-width: 1360px){
  .home-head__image{
    right: -20px;
  }

}

@media screen and (max-width: 1280px){
  .home-head{
    margin: 60px auto 160px;
  }

  .home-head__title{
    font-size: 64px;
    line-height: 60px;
  }

  .home-head__sub-title {
    max-width: 380px;
  }

  .home-head__text {
    max-width: 380px;
  }

  .home-head__image{
    position: absolute;
    top: -40px;
    right: 50px;
  }

  .home-head__image-content {
    svg {
      max-width: 500px;
    }
  }

  .home-head__btn {
    height: 48px;
    padding: 0 24px;
  }
}

@media screen and (max-width: 1024px){
  .home-head{
    margin: 36px auto 48px;
    padding-right: 34px;
  }

  .home-head__btn {
    display: flex;
    margin-top: -15vw;
    margin-right: 0;
    margin-left: auto;
  }

  .home-head__sub-title{
    max-width: 640px;
    text-align: right;
    margin-right: 0;
    margin-left: auto;
  }

  .home-head__text{
    max-width: 640px;
    text-align: right;
    margin-top: 16px;
    margin-right: 0;
    margin-left: auto;
  }

  .home-head__image{
    position: relative;
    top: auto;
    right: auto;
    margin-top: -5vw;
    text-align: center;

  }

  .home-head__image-content {
    svg {
      max-width: 71vw;
      min-width: 300px;
      height: auto;
    }
  }
}


@media screen and (max-width: 640px){
  .home-head__title{
    max-width: 320px;
    font-size: 32px;
    line-height: 39px;
  }

  .home-head__btn {
    width: 125px;
    height: 40px;
    margin-top: -10vw;
    font-size: 16px;
    line-height: 24px;
  }

  .home-head__sub-title{
    max-width: 380px;
    margin-top: 46px;
    font-size: 24px;
    line-height: 29px;
  }

  .home-head__text{
    max-width: 380px;
  }
}
