@import "../../../scss/mixin";

.home-nav {
  position: fixed;
  z-index: 10;
  right: 16px;
  top: 21vh;
  border-radius: 16px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(-45deg, #151618, #20F9F8);
  }
}

.home-nav__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  padding: 0 12px;
  background: linear-gradient(-45deg,#321D47, #311C42);
  border-radius: 16px;
  ul {
    padding: 24px 0;

    li {
      position: relative;
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      @include trCubic();
      cursor: pointer;
      &:hover,&:active {
        background: rgba(36, 242, 242, 0.2);
        border: 1px solid var(--c-primary);
        border-radius: 4px;
        path {
          fill: var(--c-primary);
        }
        .home-nav__tooltip {
          opacity: 1;
          visibility: visible;
          transform: translateX(0px) scale(1);
        }
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  ul + ul {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 20px;
      margin-left: -10px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.home-nav__tooltip {
  position: absolute;
  top: 0;
  right: calc(100% + 20px);
  padding: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(288.18deg, #F2AD7D 11.11%, #A34EFC 89.71%);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(20px) scale(.2);
  transform-origin: right center;
  @include trCubic();
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -5px;
    margin-top: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #EDA785;
  }
}


@media screen and (max-width: 1024px){
  .home-nav {
    right: 4px;
    border-radius: 8px;
  }

  .home-nav__content {
    width: 32px;
    padding: 0 4px;
    border-radius: 8px;
    ul {
      li {
        width: 24px;
        height: 24px;
      }
    }
  }

  .home-nav__tooltip {
    top: -4px;
    right: calc(100% + 12px);
  }
}

//@media screen and (max-width: 768px){
//  .home-nav__tooltip {
//    display: none;
//  }
//}
