.influencer-how {
  position: relative;
  margin: 0 auto 250px;
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    text-transform: uppercase;
    color: var(--c-pure-white);
    span {
      display: block;
    }
  }
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 788px;
    margin: 10px 0 0;
  }
  &__grid-item {
    position: relative;
    width: 50%;
    padding: 60px 27px;
  }
  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--c-pure-white);
  }
  &__card-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  &__card-num {
    margin-bottom: 16px;
    font-weight: 900;
    font-size: 36px;
    line-height: 32px;
    letter-spacing: 0.05em;
  }
  &__card-text {
    margin-left: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;

  }
}

@media screen and (max-width: 768px){
  .influencer-how__title {
    font-size: 32px;
    line-height: 39px;
    span {
      display: inline-block;
    }
  }

  .influencer-how__grid {
    margin: 35px 0 0;
    max-width: 380px;

  }

  .influencer-how__grid-item {
    padding: 25px 0;
    width: 100%;
  }

  .influencer-how__card-text {
    font-size: 14px;
  }
}
