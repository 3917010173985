.home-faq {
  max-width: 1100px;
  margin: 0 auto;
  padding: 24px 72px;
  background: rgba(217, 217, 217, 0.05);
  border-radius: 8px;
}

.home-faq__title {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  span {
    display: inline-block;
    margin: 0 auto;
    background: var(--с-gradient-green);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.home-faq__expander {
  margin-top: 64px;
}

.home-faq__expander-item {
  & + & {
    margin-top: 36px;
  }
}

.home-faq__expander-content {
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      list-style-type: none;
    }
  }
}

@media screen and (max-width: 768px){
  .home-faq {
    padding: 24px;
  }

  .home-faq__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .home-faq__expander {
    margin-top: 15px;
  }

  .home-faq__expander-item {
    & + & {
      margin-top: 16px;
    }
  }

}
