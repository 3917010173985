@mixin trCubicFast($time: .15s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trCubic($time: .3s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trLinearSlow($time: .5s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: linear
}

@mixin trCubicSlow($time: .7s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trEaseInOut($time: .3s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: ease-in-out
}

