@import "../../../scss/mixin";

.loader {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #161740;
  background-image: var(--c-bg-loader);
  opacity: 0;
  visibility: hidden;
  @include trLinearSlow();
  &--active {
    opacity: 1;
    visibility: visible;
  }
}

.loader__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 80px;
    @include trLinearSlow();
  }
}
