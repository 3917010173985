@import "../../../scss/mixin";

.home-cards {
  position: relative;
  margin: 0 auto 230px;
}
.home-cards__bg{
  position: absolute;
  top: -450px;
  left: 50%;
  transform: translateX(-50%);
}

.home-cards__content {
  position: relative;
  padding: 0 16px;
}

.home-cards__content-bg {
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  bottom: 79px;
  background: #140028;
  overflow: hidden;
  svg{
    position: absolute;
    bottom: 0;
    &:nth-child(1){
      left: 0;
    }
    &:nth-child(2){
      right: 0;
    }
  }
}
.home-cards__grid {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;

}
.home-cards__item {
  &:first-child {
    padding-top: 153px;
    margin-right: 125px;
  }
}

.home-cards__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 337px;
  padding: 0px 45px 30px;
  background-color: #05001E;
  border: 4px solid #3C0675;
  border-radius: 8px;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 30px;
    width: 100%;
    height: 100%;
    left: 26px;
    background: #000000;
    opacity: 0.5;
    filter: blur(25px);
    border-radius: 8px;
    @include trCubic()
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -9px;
    border-radius: 12px;
    background: linear-gradient(220deg, rgba(221, 189, 255, 1) 0%,rgba(34, 0, 70, 1) 50%);
    border: 4px solid #3C0675;
    @include trCubic()

  }
  &:hover, &:active {
    &:after {
      background: linear-gradient(135deg, rgba(221, 189, 255, 1) 30%, #3C0675 80%);
    }
    &:before {
      background: #7D2BD2;
      opacity: 0.2;
      filter: blur(25px);
      border-radius: 8px;
    }
  }
}

.home-cards__item-image {
  img {
    display: block;
    width: 295px;
  }
}

.home-cards__item-title {
  width: 100%;
  margin-top: -40px;
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  text-align: left;
  text-transform: uppercase;
  color: var(--c-pure-white);
}

.home-cards__item-text {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: var(--c-opacity-white);
}

.home-cards__item-actions {
  margin-top: 16px;
}

.home-cards__item-btn {
  width: 125px;
  border: 1px solid var(--c-secondary);
  &:after {
    background: var(--с-btn-gradient-purple--outline);
    border-radius: inherit;
    background-size: 200% 100%;
  }
}

@media screen and (max-width: 1024px) {
  .home-cards {
    position: relative;
    margin: 0 auto 120px;
  }

  .home-cards__item {
    &:first-child {
      margin-right: 36px;
    }
  }

  .home-cards__card {
    width: 268px;
    padding: 0px 20px 25px;
    background-color: #05001E;
  }

  .home-cards__item-image {
    img {
      display: block;
      width: 205px;
    }
  }

  .home-cards__item-title {
    margin-top: 5px;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .home-cards__item-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .home-cards__item-actions,
  .home-cards__item-btn {
    width: 100%;
  }
}

@media screen and (max-width: 640px){
  .home-cards {
    position: relative;
    margin: 0 auto 64px;
  }

  .home-cards__content-bg {
    top: 0px;
    bottom: 0px;
  }

  .home-cards__grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .home-cards__item {
    padding-bottom: 70px;
    &:first-child {
      padding-top: 70px;
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 480px){
  .home-cards__content-bg {
    svg{
      bottom: 0;
      &:nth-child(1){
        left: 0;
        top: 0;
        bottom: auto;
        transform: rotateY(180deg);
      }
      &:nth-child(2){
        right: 0;
      }
    }
  }
}
