@import "../../../scss/mixin";

.influencer-get {
  position: relative;
  margin: 0 auto 135px;
  max-width: 1168px;
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__image {
    max-width: 536px;
    margin-right: 45px;
    img{
      width: 100%;
      height: auto;
    }
  }
  &__content {
    margin-top: -50px;
    max-width: 480px;
  }
  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    text-transform: uppercase;
    background: linear-gradient(90deg, #0059FF 35.18%, #11E9D9 63.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span {
      white-space: nowrap;
    }
  }
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 70px;
  }
  &__card {
    position: relative;
    padding: 0 4.1%;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 25px;
      width: 1px;
      background: linear-gradient(132.87deg, #50FFFE 25.93%, #30B7EF 77.79%);
    }
  }
  &__card-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: var(--c-pure-white);
  }
  &__actions {
    margin-top: 60px;
    text-align: center;
  }
  &__btn {
     width: 180px;
   }
}

@media screen and (max-width: 1024px){
  .influencer-get__grid {
    flex-direction: column-reverse;
  }
  .influencer-get__content {
    margin-top: 0;
    margin-bottom: 50px;
  }
  .influencer-get__image {
    margin-right: 0;
  }

  .influencer-get__list {
    flex-direction: column;
    max-width: 576px;
    margin: 60px auto 0;
  }

  .influencer-get__card {
    padding: 16px 0 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 260px;
      height: 1px;
      background: linear-gradient(-90deg, #50FFFE 25.93%, #30B7EF 77.79%);
    }
    & + & {
      margin-top: 60px;
    }
  }
}
@media screen and (max-width: 768px){
  .influencer-get {
    margin-bottom: 80px;
  }

  .influencer-get__title {
    font-size: 32px;
    line-height: 39px;
  }
  .influencer-get__content {
    margin-bottom: 20px;
  }
  .influencer-get__image {
    max-width: 320px;
  }

  .influencer-get__list {
    margin-top: 60px;
  }

  .influencer-get__btn {
    height: 40px;
    width: auto;
  }
}
