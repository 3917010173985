@import "../../../scss/mixin";

.influencer-offer {
  position: relative;
  margin: 0 auto 345px;
  max-width: 1168px;
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__image {
    max-width: 536px;
    margin-left: 45px;
    img{
      width: 100%;
      height: auto;
    }
  }
  &__content {
    max-width: 557px;
  }
  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    text-transform: uppercase;
    background: linear-gradient(288.18deg, #FFC399 11.11%, #C48BFF 89.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span {
      white-space: nowrap;
    }

  }
  &__list {
    position: relative;
    margin-top: 50px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: var(--c-pure-white);
    list-style: disc;
    li{
      position: relative;
      margin-left: 24px;
    }
    li+ li {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1024px){
  .influencer-offer {
    margin-bottom: 80px;
  }

  .influencer-offer__grid {
    flex-direction: column-reverse;
    align-items: center;
  }

  .influencer-offer__content {
    margin: 0 auto;
  }

  .influencer-offer__image {
    margin-left: 0;
  }

  .influencer-offer__title {
    margin-bottom: 65px;
  }

  .influencer-offer__list {
    margin-top: 65px;
  }
}
@media screen and (max-width: 768px){
  .influencer-offer__title {
    font-size: 32px;
    line-height: 39px;
  }

  .influencer-offer__image {
    max-width: 320px;

  }

  .influencer-offer__list {
    font-size: 14px;
    line-height: 20px;
  }

  .influencer-get__btn {
    height: 40px;
    width: auto;
  }
}
