@import "../../../scss/mixin";

.influencer-partner {
  position: relative;
  margin: 145px auto 150px;
  max-width: 1168px;

  &__grid {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__bg {
    position: absolute;
    z-index: 0;
    top: -230px;
    left: -100px;
  }
  &__content {
    position: relative;
    z-index: 1;
    max-width: 565px;
    color: var(--c-pure-white);
  }

  &__box {
    position: relative;
    padding: 50px;
    border-radius: 28px;
    overflow: hidden;
  }
  &__box-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
    opacity: .1;
  }
  &__title {
    position: relative;
    margin-bottom: 22px;
    padding-bottom: 22px;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.04em;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 240px;
      height: 4px;
      background: linear-gradient(90deg, #7DFAFF 0%, #A34EFC 100%);
      border-radius: 4px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.04em;
  }

  &__info {
    padding: 50px 50px 0;
  }

  &__info-text {
    margin-top: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05em;
  }

  &__actions {
    margin-top: 48px;
  }

  &__btn {
    width: 180px;
  }

  &__image {
    position: relative;
    z-index: 1;
    max-width: 440px;
    margin-top: 10px;
    margin-left: 45px;
    margin-right: 45px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 1140px){
  .influencer-partner__image {
    margin-left: 25px;
  }

  .influencer-partner__box {
    padding: 22px;
  }

  .influencer-partner__info {
    padding: 33px 22px 0;
  }

}
@media screen and (max-width: 1024px){

  .influencer-partner__grid {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media screen and (max-width: 768px){
  .influencer-partner {
    margin: 50px auto 80px;
  }
  .influencer-partner__bg{
    top: -120px;
    svg {
    width: 120%
    }
  }

  .influencer-partner__title {
    font-size: 24px;
    line-height: 30px;
  }

  .influencer-partner__text {
    font-size: 14px;
    line-height: 20px;
  }

  .influencer-partner__info-text {
    font-size: 14px;
    line-height: 20px;
  }

  .influencer-partner__btn {
    height: 40px;
    width: auto;
  }

}
