.home-features {
  position: relative;
  z-index: 1;
  margin-bottom: 200px;
}

.home-features__bg {
  position: absolute;
  right: -232px;
  top: -148px;
  background: rgba(163, 78, 252, 0.16);
  mix-blend-mode: normal;
  filter: blur(200px);
  transform: matrix(-0.96, -0.26, -0.26, 0.96, 0, 0);
  svg {
    display: block;
    width: 558.87px;
    height: auto;
  }
}

.home-features__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1270px;
  margin: 0 auto;
  padding: 96px 56px 0;
  text-align: center;
  background: #140028;
  border-radius: 160px;
  background-clip: padding-box;
  border: solid 1px transparent;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(-45deg, #151618, #A34EFC);
  }
}

.home-features__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;
  color: var(--c-secondary);
}

.home-features__grid {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 110px -20px 0;
}

.home-features__card {
  position: relative;
  z-index: 1;
  width: 235px;
  min-height: 256px;
  margin: 0 20px 79px;
  padding-bottom: 30px;
  &:nth-child(1){
    .home-features__card-bg {
      background: linear-gradient(132.87deg, #EFD8A9 25.93%, #EDA277 77.79%);
    }
  }
  &:nth-child(2){
    .home-features__card-bg {
      background: linear-gradient(132.87deg, #D853E7 25.93%, #947AEB 77.79%);
    }
  }
  &:nth-child(3){
    .home-features__card-bg {
      background: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
    }
  }
  &:nth-child(4){
    .home-features__card-bg {
      background: linear-gradient(288.18deg, #FFC399 11.11%, #C48BFF 89.71%);
    }
  }
}

.home-features__card-bg {
  position: absolute;
  z-index: 1;
  top: 49px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(132.87deg, #EFD8A9 25.93%, #EDA277 77.79%);
  border-radius: 0px 0px 20px 20px;
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: -9px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    border-radius: inherit;
    background: linear-gradient(88.79deg, #05001E 1.03%, #02000D 99.04%);
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(359.37deg, #140028 0.73%, rgba(196, 139, 255, 0) 99.64%);
  }
}

.home-features__card-img {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  svg{
    display: block;
  }
}

.home-features__card-gradient {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  svg {
    display: block;
  }
}

.home-features__card-text {
  position: relative;
  z-index: 1;
  max-width: 155px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--c-pure-white);

}

@media screen and (max-width: 1280px){
  .home-features__grid {
    flex-wrap: wrap;
    max-width: 600px;
  }

}

@media screen and (max-width: 768px) {
  .home-features {
    margin-bottom: 64px;
  }

  .home-features__bg {
    display: none;
  }

  .home-features__content {
    padding: 49px 24px 36px;
    border-radius: 80px;
  }

  .home-features__title {
    font-size: 32px;
    line-height: 39px;
  }

  .home-features__grid {
    margin: 40px -20px 0;
  }

  .home-features__card {
    width: 180px;
    min-height: 210px;
    margin: 0 20px 16px;
  }

  .home-features__card-bg {
    top: 47px;
    &:before {
      top: -7px;
      right: 6px;
      bottom: 6px;
      left: 6px;
    }
  }

  .home-features__card-img {
    svg{
      width: 64px;
      height: auto;
    }
  }

  .home-features__card-text {
    font-size: 12px;
    line-height: 14px;
  }
}


