.footer {
  position: relative;
  color: var(--c-opacity-white);
  background-color: var(--c-footer-bg);
}

.footer__grid {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 64px;
}

.footer__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  span{
    display: inline-block;
  }
}

.footer__bg {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: red;

}

.footer__bg-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer__bg-2 {
  position: absolute;
  left: 34px;
  top: 0;
}

.footer__bg-3 {
  position: absolute;
  left: 77px;
  bottom: 0;
}

.footer__bg-4 {
  position: absolute;
  left: 213px;
  bottom: 0;
}

@media screen and (max-width: 768px){
  .footer__right {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .footer__bg-1 {
    width: 21px;
    height: auto;
  }

  .footer__bg-2 {
    left: 12px;
    width: 19px;
    height: auto;
  }

  .footer__bg-3 {
    left: 38px;
    width: 19px;
    height: auto;
  }

  .footer__bg-4 {
    left: 101px;
    width: 21px;
    height: auto;
  }
}
