@import "src/scss/mixin";

.influencer {
  position: relative;
  padding-top: 88px;
}


@media screen and (max-width: 768px){
  .influencer {
    padding-left: 17px;
    padding-right: 17px;
  }
}
