.home-bg-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  svg {
    &:nth-child(1){
      position: absolute;
      left: -190px;
      top: 655px;
      opacity: 0.2;
    }
    &:nth-child(2){
      position: absolute;
      right: -200px;
      top: 600px;
      opacity: 0.2;
    }
    &:nth-child(3){
      position: absolute;
      right: 0;
      top: -105px;
      opacity: 0.1;
    }
    &:nth-child(4){
      position: absolute;
      right: -37px;
      top: -50px;
      opacity: 0.1;
    }
    &:nth-child(5){
      position: absolute;
      left: 90px;
      top: -120px;
      opacity: 0.1;
    }
    &:nth-child(6){
      position: absolute;
      left: -110px;
      top: -120px;
      opacity: 0.1;
    }
  }
}
