@import "../../../scss/mixin";

.header {
  color: var(--color-text1);
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1352px;
  margin: 0 auto;
  padding: 16px;
}

.header__logo {

}


.header__actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.header__btn{
  width: 100%;
  max-width: 180px;
  & + & {
    margin-left: 16px;
  }
}


@media screen and (max-width: 768px) {
  .header__logo {
    svg{
      width: 124px;
      height: auto;
    }
  }

  .header__actions {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .header__btn{
    max-width: initial;
    width: auto;
    min-height: 32px;
    height: 40px;
    padding: 0 14px;
  }

}


@media screen and (max-width: 640px){
  .header__content {
    flex-direction: column;
  }

  .header__actions{
    margin-top: 16px;
  }
}
