@import "src/scss/mixin";

.home-publications {
  position: relative;
  margin: 0 auto 84px;
  text-align: center;
}

.home-publications__grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.home-publications__card {
  position: relative;
  z-index: 2;
  margin: 0 16px;
  &:nth-child(1){
    margin-left: -42px;
    svg {
      width: 200px;
      height: auto;
    }
  }
  &:nth-child(2){
    z-index: 3;
    margin-left: -58px;
    padding-left: 16px;
    svg {
      width: 180px;
      height: auto;
    }
  }
  &:nth-child(3){
    svg {
      margin-top: 8px;
      width: 230px;
      height: auto;
    }
  }
  &:nth-child(4){
    svg {
      width: 160px;
      height: auto;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .4;
    @include trCubic();
    &:hover,&:active {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-publications__card {
    margin: 0 8px;
    &:nth-child(1){
      margin-left: -36px;
    }
    &:nth-child(2){
      margin-left: -50px;
      padding-left: 16px;
    }
  }
}


@media screen and (max-width: 768px) {
  .home-publications {
    margin-bottom: 44px;
  }

  .home-publications__grid {
    flex-wrap: wrap;
  }

  .home-publications__card {
    width: calc(50% - 16px);
    &:nth-child(1){
      z-index: 3;
      margin-left: 8px;
    }
    &:nth-child(2){
      margin-left: 8px;
      padding-left: 0;
    }
    &:nth-child(3){
      svg {
        margin-top: -36px;
      }
    }
    &:nth-child(4){
      svg {
        margin-top: -36px;
      }
    }
  }

//  .home-publications {
//    margin: 0 auto 64px;
//  }
//
//  .home-publications__grid-item {
//    margin: 16px 8px 0;
//  }
//
//  .home-publications__card {
//    span {
//      margin-left: 11px;
//      font-size: 14px;
//    }
//  }
//
//  .home-publications__card-image {
//    svg {
//      width: 32px;
//    }
//  }
//
//  .home-publications__group {
//    margin-left: 8px;
//    .home-publications__card-image {
//      width: 25px;
//      height: 25px;
//      svg {
//        width: 25px;
//        height: 25px;
//      }
//    }
//    .home-publications__card {
//      &:not(:first-child) {
//        margin-left: -9px;
//      }
//    }
//  }
}
//@media screen and (max-width: 425px) {
//  .home-publications{
//    padding-right: 24px;
//  }
//}
