@import "../../../scss/mixin";

.home-games {
  position: relative;
  z-index: 1;
  margin: 0 auto 120px;
  max-width: 1120px;
}

.home-games__title {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: var(--c-pure-white);
}

.home-games__tabs {
  margin-top: 32px;
  padding: 27px 24px;
  background-color: #100B25;
}

.home-games__tabs-btns {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 1px;
    background: linear-gradient(-90deg, rgba(36, 242, 242, 1) 0%,rgba(45, 139, 254, 1) 100%);
    @include trCubic()
  }
}

.home-games__tabs-btn {
  position: relative;
  z-index: 2;
  padding: 12px 24px;
  font-weight: 700;
  //font-size: 14px;
  //line-height: 24px;
  //color: var(--c-opacity-white);

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--c-pure-white);

  @include trCubic();
  background: transparent;
  border: none;
  //cursor: pointer;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  z-index: 1;
  //  height: 1px;
  //  background: linear-gradient(-90deg, rgba(21, 22, 24, 1) 20%,rgba(32, 249, 248, .65) 100%);
  //  @include trCubic()
  //}
  //&:hover,&:active{
  //  color: var(--c-pure-white);
  //}
  //&--active {
  //  color: var(--c-pure-white);
  //  &:after {
  //    background: linear-gradient(90deg, rgba(36, 242, 242, 1) 0%,rgba(45, 139, 254, 1) 100%);
  //    @include trCubic()
  //  }
  //}
}

.home-games__tabs-body-item {
  table {
    width: 100%;
    table-layout: fixed;
    th {
      padding: 10px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: var(--c-primary);
    }
    td {
      padding: 0 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: var(--c-opacity-white);
      vertical-align: center;
      &:first-child {
        text-align: left;
      }
      &:nth-child(1),
      &:nth-child(2), {
        width: 30%;
        max-width: 300px;
        min-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.home-games__link {
  color: var(--c-tertiary);
  text-decoration: underline;
  &:hover,&:active{
    text-decoration: none;
  }
}

.home-games__bet {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
    width: 20px;
    height: auto;
    margin-left: 8px;
  }
}

.home-games__status {
  color: var(--c-purple);
  &--lose {
    color: var(--c-lose);
  }
  &--win {
    color: var(--c-win);
  }
}

.home-games__payout {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-opacity-white);
  &--active {
    color: var(--c-win);
  }
  svg {
    flex-shrink: 0;
    display: block;
    width: 20px;
    height: auto;
    margin-left: 8px;
  }
}

.home-games__tabs-copy-wrapper {
  padding: 8px 8px!important;
}
.home-games__tabs-copy {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px 0;
  font-size: 12px;
  line-height: 16px;
  border: none;
  background-color: transparent;
  color: var(--c-opacity-white);
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  &:hover,&:active {
    .home-games__tabs-tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0) scale(1);
    }
  }
}

.home-games__tabs-tooltip {
  position: absolute;
  bottom: calc(100% + 5px);
  right: calc(50% - 22px);
  padding: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: rgba(37,4,73,.7);
  border-radius: 8px;
  color: var(--c-pure-white);
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px) scale(0.2);
  transform-origin: center bottom;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

}

@media screen and (max-width: 768px) {
  .home-games {
    margin: 0 auto 30px;
  }

  .home-games__tabs {
    margin-top: 16px;
    padding: 0 18px 0 0;
    background-color: transparent;
  }

  .home-games__tabs-btn {
    padding: 12px 16px;
  }

  .home-games__tabs-body-item {
    table {
      th {
        padding: 5px 4px;
        font-size: 10px;
        line-height: 16px;
      }
      td {
        padding: 4px;
        font-size: 10px;
        line-height: 16px;
        text-align: center!important;
      }
    }
  }

  .home-games__tabs-copy-wrapper {
    padding: 0px 4px!important;
  }

  .home-games__bet {
    svg {
      width: 12px;
    }
  }

  .home-games__payout {
    svg {
      width: 12px;
    }
  }
}

@media screen and (max-width: 425px) {
  .home-games__tabs-body-item {
    table {
      th, td {
        padding-left: 2px;
        padding-right: 2px;
        &:first-child {
          padding-left: 0;
        }
        &:nth-child(3) {
          width: 21px;
        }
        &:nth-child(4) {
          width: 35px;
        }
        &:nth-child(5) {
          width: 35px;
        }
        &:nth-child(6) {
          width: 18px;
        }
      }
    }
  }
}
