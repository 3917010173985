/*Open Sans*/
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansBold.eot');
  src: local('Open Sans Bold'), local('Open-Sans-Bold'),
  url('../assets/fonts/OpenSansBold.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansBold.woff2') format('woff2'),
  url('../assets/fonts/OpenSansBold.woff') format('woff'),
  url('../assets/fonts/OpenSansBold.ttf') format('truetype'),
  url('../assets/fonts/OpenSansBold.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansSemiBold.eot');
  src: local('Open Sans Semi Bold'), local('Open-Sans-Semi-Bold'),
  url('../assets/fonts/OpenSansSemiBold.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansSemiBold.woff2') format('woff2'),
  url('../assets/fonts/OpenSansSemiBold.woff') format('woff'),
  url('../assets/fonts/OpenSansSemiBold.ttf') format('truetype'),
  url('../assets/fonts/OpenSansSemiBold.svg#OpenSansSemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansMedium.eot');
  src: local('Open Sans Medium'), local('Open-Sans-Medium'),
  url('../assets/fonts/OpenSansMedium.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansMedium.woff2') format('woff2'),
  url('../assets/fonts/OpenSansMedium.woff') format('woff'),
  url('../assets/fonts/OpenSansMedium.ttf') format('truetype'),
  url('../assets/fonts/OpenSansMedium.svg#OpenSansMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSansRegular.eot');
  src: local('Open Sans Regular'), local('Open-Sans-Regular'),
  url('../assets/fonts/OpenSansRegular.eot') format('embedded-opentype'),
  url('../assets/fonts/OpenSansRegular.woff2') format('woff2'),
  url('../assets/fonts/OpenSansRegular.woff') format('woff'),
  url('../assets/fonts/OpenSansRegular.ttf') format('truetype'),
  url('../assets/fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/*Gilroy*/
@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Heavy.eot');
  src: url('../assets/fonts/Gilroy-Heavy.eot') format('embedded-opentype'),
  url('../assets/fonts/Gilroy-Heavy.woff2') format('woff2'),
  url('../assets/fonts/Gilroy-Heavy.woff') format('woff'),
  url('../assets/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Bold.eot');
  src: url('../assets/fonts/Gilroy-Bold.eot') format('embedded-opentype'),
  url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
  url('../assets/fonts/Gilroy-Bold.woff') format('woff'),
  url('../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Medium.eot');
  src: url('../assets/fonts/Gilroy-Medium.eot') format('embedded-opentype'),
  url('../assets/fonts/Gilroy-Medium.woff2') format('woff2'),
  url('../assets/fonts/Gilroy-Medium.woff') format('woff'),
  url('../assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Regular.eot');
  src: url('../assets/fonts/Gilroy-Regular.eot') format('embedded-opentype'),
  url('../assets/fonts/Gilroy-Regular.woff2') format('woff2'),
  url('../assets/fonts/Gilroy-Regular.woff') format('woff'),
  url('../assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
