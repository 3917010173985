@import "src/scss/mixin";

.home {
  position: relative;
  padding-top: 88px;
}

.home__bottom {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 37px;
}

@media screen and (max-width: 768px){
  .home__bottom {
    margin-top: 0px;
  }
}
