@import "src/scss/mixin";

.expander {
  @include trCubicFast();
//  &:hover, &:active {
//    border: 1px solid var(--color-title1);
//    background-color: var(--color-text5);
//    .expander__arrow {
//      color: var(--color-title2);;
//      background-color: var(--color-expander-bg);
//    }
//  }
//  &--active, {
//    border: 1px solid var(--color-title1);
//    background-color: var(--color-text5);
//    .expander__title {
//      color: var(--color-title2);
//    }
//
//  }
}

.expander__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--c-pure-white);
  cursor: pointer;
}

.expander__title {
  padding: 14px 0;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  @include trCubicFast();
}

.expander__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--color-expander-arrow);
  color: var(--color-text3);
}

.expander__body {
  margin-top: -12px;
  padding: 0 48px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-pure-white);
}

@media screen and (max-width: 768px){
  .expander__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    color: var(--c-pure-white);
    cursor: pointer;
  }

  .expander__arrow {
    height: 24px;
    width: 24px;
    margin-right: 20px;
  }

  .expander__body {
    margin-top: 12px;
    padding: 0;
  }
}




