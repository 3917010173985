@import "scss/fonts.scss";
@import "scss/mixin";
@import "scss/reset";

:root {
  --c-pure-white: #ffffff;
  --c-opacity-white: rgba(255, 255, 255, 0.45);
  --c-primary: #18F3FF;
  --c-secondary: #A34EFC;
  --c-secondary--hover: #572789;
  --c-purple: #A34EFC;
  --c-tertiary: #2D8BFE;
  --c-accent: #ff3e00;
  --c-error: #FF0000;
  --c-text: #444444;
  --c-dark: #000000;
  --c-bg: #05001E;
  --c-footer-bg: #151415;
  --c-lose: #F2AD7D;
  --c-input-border: #180C2D;
  --c-win: var(--c-primary);
  --c-btn-disabled-bg: #444444;
  --c-btn-disabled-text: rgba(255, 255, 255, 0.45);
  --с-gradient-green: linear-gradient(132.87deg, #24F2F2 25.93%, #2D8BFE 77.79%);
  --с-btn-gradient-green--fill: linear-gradient(132.87deg, #24F2F2, #2D8BFE, #24F2F2);
  --с-btn-gradient-orange--fill: linear-gradient(132.87deg, #F3A957 15.93%, #C21D86 57.79%);;
  --с-btn-gradient-green--outline: linear-gradient(132.87deg, transparent 50% , #18F3FF 100%);
  --с-btn-gradient-purple--outline: linear-gradient(132.87deg, transparent 50% , #A34EFC 100%);
  //--gradient-green-hover-color: linear-gradient(132.87deg, #1BB7B7 25.93%, #2572CF 77.79%);
  //--gradient-orange-color: linear-gradient(288.18deg, #F2AD7D 11.11%, #A34EFC 89.71%);
  --c-bg-loader: linear-gradient(to right bottom, #431574, #301964, #211952, #161740, #10132d, #10142b, #111528, #121626, #161e34, #192743, #1a3052, #183962);
}

html,body {
  background-color: var(--c-bg);
}

body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.body--fixed {
     position: static;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     overflow: hidden;
   }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.app-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;

  &__top {
    position: absolute;
    top: 0;
    z-index: 20;
    width: 100%;
    flex-shrink: 0
  }

  &__content {
    flex: 1 0 auto;
  }

  &__bottom {
    width: 100%;
    flex-shrink: 0;
  }
}

.app__wrapper {
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}
