@import "../../../scss/mixin";

.home-staking {
  position: relative;
  margin: 0 auto 200px;
  max-width: 1210px;
}

.home-staking__grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.home-staking__image {
  margin-top: 20px;
  width: 688px;
  flex-shrink: 0;
  svg{
    width: 100%;
    height: auto;
  }
}

.home-staking__content {
  margin-left: -48px;
}

.home-staking__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  background: linear-gradient(132.87deg, #50FFFE 25.93%, #30B7EF 77.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}

.home-staking__text {
  margin-top: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-pure-white);
}

.home-staking__cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 85px;
}

.home-staking__card {
  display: flex;
  flex: content;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 140px;
}

.home-staking__card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background: #0D1D3C;
}

.home-staking__card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 25px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--c-pure-white);
  background: #070624;
}

.home-staking__actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.home-staking__actions-text {
  max-width: 330px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-pure-white);
}

.home-staking__btn {
  margin-left: 8px;
  white-space: nowrap;
}

@media screen and (max-width: 1280px){
  .home-staking__image {
    width: 550px;
  }
}

@media screen and (max-width: 1024px) {
  .home-staking__grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .home-staking__title {
    max-width: 580px;
  }

  .home-staking__text {
    max-width: 580px;
  }

  .home-staking__image {
    max-width: 550px;
    width: 100%;
    margin-top: 24px;
    svg{
      width: 100%;
      height: auto;
    }
  }

  .home-staking__content {
    margin-left: auto;
    margin-right: auto;
  }

  .home-staking__cards {
    margin-top: -60px;
  }

  .home-staking__card {
    & + & {
      margin-left: 36px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-staking {
    margin: 0 auto 48px;
    padding-right: 24px;
  }

  .home-staking__title {
    max-width: 480px;
    font-size: 32px;
    line-height: 39px;
  }

  .home-staking__text {
    max-width: 480px;
  }

  .home-staking__cards {
    align-items: flex-start;
    margin-top: -30px;
  }

  .home-staking__card {
    max-width: 95px;
    & + & {
      margin-left: 12px;
    }
  }

  .home-staking__card-text {
    padding: 12px 10px;
  }

  .home-staking__actions {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
  }

  .home-staking__actions-text {
    max-width: 330px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .home-staking__btn {
    margin-top: 48px;
    margin-left: 0;
  }

}
