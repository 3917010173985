@import "../../../scss/mixin";

.home-casino {
  position: relative;
  margin: 0 auto 120px;
  max-width: 1210px;
}

.home-casino__grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.home-casino__image {
  margin-top: -40px;
  margin-right: -48px;
  width: 645px;
  flex-shrink: 0;
  img{
    width: 100%;
    height: auto;
  }
}

.home-casino__content {
  max-width: 580px;
  //margin-left: -48px;

}

.home-casino__title {
  text-align: right;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #0058FF 0%, #11E9D9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  span {
    white-space: nowrap;
  }

}

.home-casino__list {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: var(--c-pure-white);
  li{
    margin-top: 24px;
    position: relative;
    padding-left: 58px;
  }
  svg {
    position: absolute;
    top: -12px;
    left: 0;
  }
}

@media screen and (max-width: 1280px){
  .home-casino__image {
    width: 550px;
  }
}

@media screen and (max-width: 1024px) {
  .home-casino__grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .home-casino__title {
    max-width: 580px;
  }

  .home-casino__text {
    max-width: 580px;
  }

  .home-casino__image {
    max-width: 550px;
    width: 100%;
    margin-top: 24px;
    svg{
      width: 100%;
      height: auto;
    }
  }

  .home-casino__content {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .home-casino {
    margin: 0 auto 48px;
    padding-right: 24px;
  }

  .home-casino__title {
    max-width: 480px;
    margin: 0 auto;
    font-size: 32px;
    line-height: 39px;
    text-align: left;
  }

  .home-casino__list {
    max-width: 480px;
    li {
      padding-left: 38px;
    }
    svg{
      left: -20px;
    }
  }

}
