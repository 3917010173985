@import "src/scss/mixin";

.app-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box;
  @include trEaseInOut();
  text-decoration: none;
  text-transform: none;
  text-transform: none;
  text-rendering: auto;

  &__content {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    border-radius: 50%;
    margin-top: -20px;
    width: 12px;
    height: 12px;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
    color: var(--c-opacity-white);
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      animation-fill-mode: both;
      animation: bblFadInOut 1.8s infinite ease-in-out;
    }

    &:before {
      left: -24px;
      animation-delay: -0.32s;
    }
    &:after {
      left: 24px;
    }

    @keyframes bblFadInOut {
      0%, 80%, 100% { box-shadow: 0 12px 0 -8px }
      40% { box-shadow: 0 12px 0 0 }
    }

  }


  //&:disabled {
  //  pointer-events: none;
  //  opacity: .6;
  //}
  &[disabled] {
    border: 1px solid var(--c-btn-disabled-bg);
    background: var(--c-btn-disabled-bg);
    color: var(--c-btn-disabled-text);
    pointer-events: none;

    &:after {
      display: none;
    }
  }

  //---size
  &--big {
    height: 64px;
    padding: 12px 24px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    border-radius: 14px;
  }

  &--small {
    height: 40px;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border-radius: 10px;
  }

  //---type
  &--fill {
    color: var(--c-dark);
    background: var(--с-btn-gradient-green--fill);
    background-size: 200% 100%;
    border: 1px solid var(--с-gradient-green);

    &:hover {
      background-position: 100% 0;
    }

    &:active {
      background-position: 100% 0;
    }
  }

  &--fill-2 {
    color: var(--c-dark);
    background: var(--с-btn-gradient-orange--fill);
    background-size: 200% 100%;
    border: 1px solid var(--с-gradient-green);

    &:hover {
      background-position: 100% 0;
    }

    &:active {
      background-position: 100% 0;
    }

  }

  &--outline {
    position: relative;
    border: 1px solid var(--c-primary);
    background-color: transparent;
    color: var(--c-pure-white);
    overflow: hidden;
    @include trEaseInOut();

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -1px;
      bottom: -1px;
      right: -1px;
      background: var(--с-btn-gradient-green--outline);
      border-radius: inherit;
      background-size: 200% 100%;
      @include trEaseInOut();
    }

    path {
      fill: var(--c-pure-white);
      @include trEaseInOut();
    }

    &:hover {
      &:after {
        background-position: 100% 0;
      }
    }

    &:active {
      &:after {
        background-position: 100% 0;
      }
    }

    &[disabled] {
      border: 1px solid var(--c-btn-disabled-bg);
      background-color: transparent;
      color: var(--c-btn-disabled-text);
      pointer-events: none;

      &:after {
        display: none;
      }
    }
  }
}

.app-button-append {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

a.app-button {
  .app-button__content {
    margin-top: 3px;
  }
}
